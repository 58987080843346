<template>
    <TopBanner2 />

    <main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
        <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
            <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                <header class="mb-4 lg:mb-6 not-format">
                    <address class="flex items-center mb-6 not-italic">
                        <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                            <img class="mr-4 w-16 h-16 rounded-full" src="../../assets/foto_perfil_2.jpg" alt="foto_perfil">
                            <div>
                                <a href="#" rel="author" class="text-xl font-bold text-gray-900 dark:text-white">Tulio González Abuter</a>
                                <p class="text-base text-gray-500 dark:text-gray-400">Abogado</p>
                                <p class="text-base text-gray-500 dark:text-gray-400"><time pubdate datetime="2023-11-21" title="21 de Noviembre, 2023"> <!-- 21 de Noviembre, 2023 --> </time></p>
                            </div>
                        </div>
                    </address>
                    <h1 style="text-align: center;" class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">Defensoría Penal: Representación Legal Firme y Dedicada</h1>
                </header>
                <!-- texto alineado -->

                <p class="mb-3 text-lg text-gray-500 md:text-xl dark:text-gray-400" style="text-align: justify;">En nuestra sección de Defensoría Penal nos dedicamos a la representación y defensa de individuos y empresas en el ámbito del derecho penal. Entendemos la gravedad y el impacto que un proceso penal puede tener en su vida de las personas. </p>


                <!-- add space -->
                <div class="h-8"></div>


                <figure>
                    <img src="../../assets/carcel.jpg" alt="">
                    <figcaption><p class="mb-3 text-gray-500 dark:text-gray-400"><span class="italic"></span></p></figcaption>
                </figure>

                <h4 class="text-2xl font-bold dark:text-white">Nuestros Servicios en Defensoría Penal</h4>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">Nuestro equipo ofrece experiencia y conocimiento en una amplia gama de asuntos penales, incluyendo:</p>



                <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-gray-400">
                    <li style="text-align: justify;">
                        <strong>Defensa en Procesos Penales:</strong> Representación y defensa en todo tipo de procesos penales, desde las etapas iniciales de la investigación hasta el juicio y, si es necesario, en los recursos de apelación o nulidad según corresponda.

                    </li>
                    <li style="text-align: justify;">
                        <strong>Asesoramiento en Derecho Penal: </strong> Orientación legal preventiva para individuos y empresas, ayudando a comprender las implicaciones penales de diversas acciones y decisiones.
                    </li>
                    <li style="text-align: justify;">
                        <strong>Delitos Económicos y Financieros:</strong> Especialización en la defensa de casos relacionados con delitos económicos, como fraude, lavado de dinero, delitos tributarios, entre otros.
                    </li>
                    <li style="text-align: justify;">
                        <strong>Delitos contra la Propiedad:  </strong> Defensa en casos de robo, hurto, estafa y otros delitos contra la propiedad.
                    </li>
                    <li style="text-align: justify;">
                        <strong>Delitos contra las Personas:</strong> Representación legal en casos de delitos que afectan la integridad física o psicológica de las personas, como lesiones, homicidios, violencia intrafamiliar, entre otros.
                    </li>
                </ol>



                <!-- add space -->
                <div class="h-8"></div>



                <!-- add space -->
                <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Nuestro Compromiso</h2>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                    Nuestro compromiso es proporcionar una defensa legal que proteja sus derechos y busque el mejor resultado posible dadas las circunstancias de su caso. Entendemos la importancia de una comunicación clara y constante con nuestros clientes, manteniéndolos informados y participativos en cada etapa del proceso.
                </p>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                    Nos esforzamos por manejar cada caso con la mayor discreción, profesionalismo y dedicación, reconociendo la importancia de una defensa legal efectiva en situaciones de esta naturaleza.
                </p>
                <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Contáctenos</h2>

                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                    Si usted o alguien que conoce necesita asesoramiento o representación en un asunto penal, por favor contáctenos. Estamos aquí para ofrecerle una defensa legal experta y comprometida.
                </p>

                <!-- add space -->
                <div class="h-8"></div>




            </article>
        </div>
    </main>

    <ArticleAside_2 />

</template>

<script>
    import TopBanner2 from "../../components/TopBanner2.vue";
    import ArticleAside_2 from "../../components/ArticleAside_2.vue";

    export default {
        name: "corretajes_page",
        components: {
            TopBanner2,
            ArticleAside_2,
        }
    };

</script>