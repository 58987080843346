<template>
    <TopBanner2 />

    <main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
        <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
            <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                <header class="mb-4 lg:mb-6 not-format">
                    <address class="flex items-center mb-6 not-italic">
                        <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                            <img class="mr-4 w-16 h-16 rounded-full" src="../../assets/foto_perfil_2.jpg" alt="foto_perfil">
                            <div>
                                <a href="#" rel="author" class="text-xl font-bold text-gray-900 dark:text-white">Tulio González Abuter</a>
                                <p class="text-base text-gray-500 dark:text-gray-400">Abogado</p>
                                <p class="text-base text-gray-500 dark:text-gray-400"><time pubdate datetime="2023-11-21" title="21 de Noviembre, 2023"><!-- 21 de Noviembre, 2023 --></time></p>
                            </div>
                        </div>
                    </address>
                    <h1 style="text-align: center;" class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">Recursos de Amparo y Protección: Defendiendo sus Derechos Fundamentales</h1>
                </header>
                <!-- texto alineado -->

                <p class="mb-3 text-lg text-gray-500 md:text-xl dark:text-gray-400" style="text-align: justify;">Los Recursos de Amparo y Protección, nos permiten defender y proteger los derechos fundamentales de nuestros clientes. Entendemos la importancia crítica de actuar rápidamente y con conocimiento profundo de la ley para salvaguardar sus derechos. </p>

                <!-- add space -->
                <div class="h-8"></div>


                <figure>
                    <img src="../../assets/martillo.jpg" alt="">
                    <figcaption><p class="mb-3 text-gray-500 dark:text-gray-400"><span class="italic"></span></p></figcaption>
                </figure>

                <h4 class="text-2xl font-bold dark:text-white">Nuestros Servicios en Recursos de Amparo y Protección</h4>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">Ofrecemos asesoramiento legal y representación en la interposición y seguimiento de:</p>



                <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-gray-400">
                    <li style="text-align: justify;">
                        <strong>1.	Recursos de Amparo: </strong>Orientación y representación en la presentación de recursos de amparo ante la Corte de Apelaciones y la Corte Suprema, buscando la protección de derechos fundamentales como la libertad personal y seguridad individual, frente a actos de autoridades o particulares que puedan resultar en su privación o amenaza.
                    </li>
                    <li style="text-align: justify;">
                        <strong>2.	Recursos de Protección:</strong>Asistencia en la preparación y tramitación de recursos de protección ante las Cortes de Apelaciones, destinados a resguardar la garantía de los derechos constitucionales frente a actos u omisiones arbitrarios o ilegales que los vulneren o amenacen.
                    </li>
                    <li style="text-align: justify;">
                        <strong>3.	Asesoramiento Preventivo: </strong>Consultoría para identificar posibles vulneraciones de derechos y asesoramiento sobre las mejores estrategias para protegerlos.
                    </li>
                    <li style="text-align: justify;">
                        <strong>4.	Seguimiento y Apelaciones: </strong>Gestión y seguimiento de recursos, incluyendo la presentación de apelaciones y otros recursos procesales necesarios para la efectiva protección de sus derechos.
                    </li>

                </ol>






                <!-- add space -->
                <div class="h-8"></div>

                <!-- add space -->
                <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Nuestro Compromiso</h2>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                    Nos comprometemos a brindar una representación legal que se caracteriza por su rapidez, eficiencia y conocimiento profundo de los mecanismos de protección de derechos fundamentales. Entendemos la urgencia y la importancia de estos recursos, y nos dedicamos a trabajar con la máxima diligencia para proteger sus derechos.
                </p>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                    Nuestro equipo está altamente calificado y constantemente actualizado en la jurisprudencia y legislación relevante, asegurando que su caso será manejado con la mayor competencia y profesionalismo.
                </p>
                <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Contáctenos</h2>

                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                    Si usted siente que sus derechos fundamentales están siendo vulnerados o amenazados, no dude en contactarnos. Estamos aquí para asesorarlo y representarlo en la defensa y protección de sus derechos más esenciales.
                </p>

                <!-- add space -->
                <div class="h-8"></div>




            </article>
        </div>
    </main>

    <ArticleAside_2 />

</template>

<script>
    import TopBanner2 from "../../components/TopBanner2.vue";
    import ArticleAside_2 from "../../components/ArticleAside_2.vue";

    export default {
        name: "corretajes_page",
        components: {
            TopBanner2,
            ArticleAside_2,
        }
    };

</script>