<template>
    <TopBanner2 />

    <main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
        <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
            <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                <header class="mb-4 lg:mb-6 not-format">
                    <address class="flex items-center mb-6 not-italic">
                        <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                            <img class="mr-4 w-16 h-16 rounded-full" src="../../assets/foto_perfil_2.jpg" alt="foto_perfil">
                                <div>
                                    <a href="#" rel="author" class="text-xl font-bold text-gray-900 dark:text-white">Tulio González Abuter</a>
                                    <p class="text-base text-gray-500 dark:text-gray-400">Abogado</p>
                                    <p class="text-base text-gray-500 dark:text-gray-400"><time pubdate datetime="2023-11-21" title="21 de Noviembre, 2023"><!-- 21 de Noviembre, 2023 --></time></p>
                                </div>
                        </div>
                    </address>
                    <h1 style="text-align: center;" class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">Herencias</h1>
                </header>
                <!-- texto alineado -->

                <p class="mb-3 text-lg text-gray-500 md:text-xl dark:text-gray-400" style="text-align: justify;" > </p>

                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">Uno de los principales motivos de consulta jurídica son las herencias, es decir, el conjunto de bienes, derechos y obligaciones transmisibles de una persona al momento de fallecer. Generalmente comenzamos con el trámite de la Posesión Efectiva, para lo cual debemos tener claro cuál es el pasivo y el activo del causante, y quienes son los herederos; además de realizar el cálculo del impuesto a la herencia. Una buena asesoría en esta materia implica un acabado estudio que permita realizar la partición y adjudicación de los bienes en forma lo más justa y equitativa posible. Este trabajo es uno de los que más criterio jurídico y práctico requiere la profesión de abogado. </p>

                <!-- add space -->
                <div class="h-8"></div>


                <figure><img src="../../assets/herencia.jpg" alt="">
                    <figcaption><p class="mb-3 text-gray-500 dark:text-gray-400"><span class="italic"></span></p></figcaption>
                </figure>

                <!-- add space -->
                <div class="h-8"></div>


                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;"> </p>

                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;"> </p>



              
            </article>
        </div>
    </main>

    <ArticleAside_2 />

</template>

<script>
    import TopBanner2 from "../../components/TopBanner2.vue";
    import ArticleAside_2 from "../../components/ArticleAside_2.vue";

    export default {
        name: "herencias_page",
        components: {
            TopBanner2,
            ArticleAside_2,
        }
    };

</script>