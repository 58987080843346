<template>
    <TopBanner2 />

    <main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
        <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
            <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                <header class="mb-4 lg:mb-6 not-format">
                    <address class="flex items-center mb-6 not-italic">
                        <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                            <img class="mr-4 w-16 h-16 rounded-full" src="../../assets/foto_perfil_2.jpg" alt="foto_perfil">
                            <div>
                                <a href="#" rel="author" class="text-xl font-bold text-gray-900 dark:text-white">Tulio González Abuter</a>
                                <p class="text-base text-gray-500 dark:text-gray-400">Abogado</p>
                                <p class="text-base text-gray-500 dark:text-gray-400"><time pubdate datetime="2023-11-21" title="21 de Noviembre, 2023"><!-- 21 de Noviembre, 2023 --></time></p>
                            </div>
                        </div>
                    </address>
                    <h1 style="text-align: center;" class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">Estudios de Títulos Inmobiliarios: Seguridad y Confianza en su Inversión</h1>
                </header>
                <!-- texto alineado -->

                <p class="mb-3 text-lg text-gray-500 md:text-xl dark:text-gray-400" style="text-align: justify;">Nuestra sección especializada en Estudios de Títulos Inmobiliarios es un servicio esencial para garantizar la seguridad jurídica de sus transacciones inmobiliarias. Ofrecemos un análisis exhaustivo y detallado de los títulos de propiedad, asegurando la tranquilidad y seguridad que usted necesita. </p>

                <h4 class="text-2xl font-bold dark:text-white">Nuestro servicio de estudio de títulos inmobiliarios incluye:</h4>


                <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-gray-400">
                    <li style="text-align: justify;">
                        <strong>1.	Revisión Histórica de la Propiedad:</strong>Realizamos un análisis detallado de la historia de la propiedad, examinando todos los registros y transacciones previas para asegurar la claridad y continuidad de la cadena de títulos.
                    </li>
                    <li style="text-align: justify;">
                        <strong>2.	Verificación de Documentación: </strong>Examinamos minuciosamente todos los documentos relacionados con la propiedad, incluyendo escrituras, registros, planos y cualquier otro documento relevante, debidamente certificado por el Conservador de Bienes Raíces.
                    </li>
                    <li style="text-align: justify;">
                        <strong>3.	Detección de Posibles Problemas:</strong>Identificamos y alertamos sobre cualquier irregularidad o problema potencial, como embargos, hipotecas, servidumbres, restricciones urbanísticas o litigios pendientes que puedan afectar la propiedad.
                    </li>
                    <li style="text-align: justify;">
                        <strong>4.	Informe Detallado: </strong> Proporcionamos un informe completo que incluye el análisis realizado, los hallazgos y las recomendaciones, permitiéndole tomar decisiones informadas y seguras.
                    </li>
                    <li style="text-align: justify;">
                        <strong>5.	Asesoría Legal Integral: </strong> Ofrecemos asesoramiento legal completo en relación con los resultados del estudio de títulos y las posibles acciones a seguir para resolver cualquier inconveniente detectado.
                    </li>
                </ol>






                <!-- add space -->
                <div class="h-8"></div>


                <figure>
                    <img src="../../assets/EstudiosTitulos.jpg" alt="">
                    <figcaption><p class="mb-3 text-gray-500 dark:text-gray-400"><span class="italic"></span></p></figcaption>
                </figure>

                <!-- add space -->
                <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Nuestro Compromiso</h2>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                    Comprendemos la importancia de un estudio de títulos realizado con precisión y profesionalismo. Brindamos un servicio que le ofrezca la máxima seguridad jurídica, minimizando los riesgos y protegiendo su inversión.
                </p>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                    Estamos preparados para enfrentar y resolver las complejidades que pueden surgir en el estudio de títulos inmobiliarios.
                </p>
                    <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Contáctenos</h2>

                    <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                        Para más información sobre nuestros servicios de estudio de títulos inmobiliarios o para agendar una consulta, no dude en contactarnos. Estamos aquí para asegurar que su inversión inmobiliaria esté fundamentada en una base legal sólida y confiable.
                    </p>

                <!-- add space -->
                <div class="h-8"></div>




            </article>
        </div>
    </main>

    <ArticleAside_2 />

</template>

<script>
    import TopBanner2 from "../../components/TopBanner2.vue";
    import ArticleAside_2 from "../../components/ArticleAside_2.vue";

    export default {
        name: "EstudiosTitulos",
        components: {
            TopBanner2,
            ArticleAside_2,
        }
    };

</script>