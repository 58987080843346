<template>
    <aside aria-label="Related articles" class="py-8 lg:py-24 bg-gray-50 dark:bg-gray-800">
        <div class="px-4 mx-auto max-w-screen-xl">
            <h2 class="mb-8 text-2xl font-bold text-gray-900 dark:text-white">Servicios relacionados</h2>
            <div class="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
                <article class="max-w-xs" v-for="article in randomArticles" :key="article.id">
                    <a href="#">
                        <img :src="article.image" class="mb-5 rounded-lg" :alt="article.title">
                    </a>
                    <h2 class="mb-2 text-xl font-bold leading-tight text-gray-900 dark:text-white">
                        <a href="#">{{ article.title }}</a>
                    </h2>
                    <p class="mb-4 text-gray-500 dark:text-gray-400" style="text-align: justify;">{{ article.description }}</p>
                    <a :href="article.link" class="inline-flex items-center font-medium underline underline-offset-4 text-primary-600 dark:text-primary-500 hover:no-underline">
                        Leer más →
                    </a>
                </article>
            </div>
        </div>
    </aside>
</template>

<script>
import { onMounted, ref } from 'vue';
import { initFlowbite } from 'flowbite';

export default {
    name: 'ArticleAside_2',

    setup() {
        const articles = ref([
            {
                id: 1,
                image: require("@/assets/contract.jpg"),
                title: "Derecho Contractual",
                description: "Revisión y redacción de contratos y asesoría en disputas contractuales; demandas de indemnizaciones por daños y perjuicios.",
                link: "/servicios/DerechoContractual"
            },
            {
                id: 2,
                image: require("@/assets/herencia.jpg"),
                title: "Herencias",
                description: "Las herencias, que incluyen bienes y deudas del fallecido, son una consulta jurídica común. Iniciamos con la Posesión Efectiva, identificando pasivos, activos y herederos, y calculando impuestos. Una asesoría adecuada busca una distribución justa y equitativa.",
                link: "/servicios/Herencias"
            },
            {
                id: 3,
                image: require("@/assets/familia_2.jpg"),
                title: "Derecho de Familia",
                description: "Revisión y redacción de contratos y asesoría en disputas contractuales; demandas de indemnizaciones por daños y perjuicios.",
                link: "/servicios/DerechoFamilia"
            },
            {
                id: 4,
                image: require("@/assets/casa_1.jpg"),
                title: "Asesoría Inmobiliaria",
                description: "Revisión y elaboración de contratos de promesa y compraventa de bienes raíces, estudios de títulos; arrendamientos, usufructos, derechos de herencia y posesiones efectivas. Consultoría en negociaciones y litigios inmobiliarios.",
                link: "/servicios/AsesoriaInmobiliaria"
            },
            {
                id: 5,
                image: require("@/assets/corretajes.jpg"),
                title: "Corretaje",
                description: "El corretaje de bienes raíces, vital en el sector inmobiliario, implica mediar entre partes y asesorar, incluyendo conocimientos sobre créditos hipotecarios y legalidad de propiedades.",
                link: "/servicios/Corretajes"
            },
            {
                id: 6,
                image: require("@/assets/EstudiosTitulos.jpg"),
                title: "Estudios de títulos",
                description: "Se evalúan Estudios de Títulos para propiedades, asegurando la correcta verificación de titularidades y la identificación de gravámenes, así como la validez de documentos como escrituras y registros públicos.",
                link: "/servicios/EstudiosTitulos"
            },
            {
                id: 7,
                image: require("@/assets/carcel.jpg"),
                title: "Defensoría Penal",
                description: "Asesoría en la defensa e investigación de causas penales; representación en diversos juicios orales para la correcta y justa aplicación del derecho.",
                link: "/servicios/DefensoriaPenal"
            },
            {
                id: 8,
                image: require("@/assets/martillo_2.jpg"),
                title: "Recursos de amparo y protección",
                description: "Se preparan recursos con argumentación legal sólida, asegurando una protección efectiva de los derechos de los clientes en todas las etapas judiciales.",
                link: "/servicios/RecursoAmparo"
            }
        ]);

        const randomArticles = ref([]);
        console.log(randomArticles);

        const pickRandomArticles = () => {
            const shuffled = articles.value.sort(() => 0.5 - Math.random());
            randomArticles.value = shuffled.slice(0, 4);

        };

        onMounted(() => {
            initFlowbite();
            pickRandomArticles();
        });

        return { randomArticles };
    },
};
</script>
