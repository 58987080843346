<template>
    <footer class="bg-gray-50 dark:bg-gray-800 antialiased">
        <div class="p-4 py-6 mx-auto max-w-screen-xl md:p-8 lg:p-10">
            <div class="text-center">
                <a href="#" class="flex justify-center items-center mb-5 text-2xl font-semibold text-gray-900 dark:text-white">
                    Tulio González Abuter, Abogado
                </a>

                <div class="flex justify-center items-center mb-3">
                    <p class="text-gray-500 dark:text-gray-400">Condell 585, Los Ángeles, Región del Biobío, Chile.</p>
                </div>

                <!-- Flex container with center alignment -->
                <div class="flex justify-center items-center mb-3">
                    <!-- SVG Icon -->
                    <svg xmlns="http://www.w3.org/2000/svg"
                         class="h-5 w-5 mr-2"
                         fill="green"
                         viewBox="0 0 24 24">
                        <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                    </svg>

                    <!-- Contact Text Centered -->
                    <p class="text-gray-500 dark:text-gray-400">Teléfono de contacto: +56 9 6515 0260</p>
                </div>
                <div class="flex justify-center items-center mb-3">
                    <svg class="w-5 h-5 mr-2 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="blue" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 2-8.4 7.05a1 1 0 0 1-1.2 0L1 2m18 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1m18 0v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2" />
                    </svg>
                    <p class="text-gray-500 dark:text-gray-400">   Correo electrónico: gonzalezabuter@gmail.com </p>
                </div>

                <span class="block text-sm text-center text-gray-500 dark:text-gray-400">
                    © 2023-2024 <a href="#" class="hover:underline">Tulio González Abuter</a>. Todos los derechos reservados.
                </span>
            </div>
            </div>
    </footer>

</template>

<script>

    export default {
        name: 'FooterComponent'
    };

</script>