<template>
    <TopBanner2 />

    <main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
        <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
            <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                <header class="mb-4 lg:mb-6 not-format">
                    <address class="flex items-center mb-6 not-italic">
                        <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                            <img class="mr-4 w-16 h-16 rounded-full" src="../../assets/foto_perfil_2.jpg" alt="foto_perfil">
                            <div>
                                <a href="#" rel="author" class="text-xl font-bold text-gray-900 dark:text-white">Tulio González Abuter</a>
                                <p class="text-base text-gray-500 dark:text-gray-400">Abogado</p>
                                <p class="text-base text-gray-500 dark:text-gray-400"><time pubdate datetime="2023-11-21" title="21 de Noviembre, 2023"><!-- 21 de Noviembre, 2023 --></time></p>
                            </div>
                        </div>
                    </address>
                    <h1 style="text-align: center;" class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">Derecho de Familia</h1>
                </header>
                <!-- texto alineado -->

                <p class="mb-3 text-lg text-gray-500 md:text-xl dark:text-gray-400" style="text-align: justify;">La Declaración Universal de los Derechos Humanos (1948) dice Preámbulo: “Considerando que la libertad, la justicia y la paz en el mundo tienen por base el reconocimiento de la dignidad intrínseca y de los derechos iguales e inalienables de todos los miembros de la familia humana;” </p>

                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;"> Evidenciando un concepto que traspasa todas las legislaciones; asimismo la Declaración Americana de los Derechos y Deberes del Hombre dice que “toda persona tiene derecho a constituir familia, elemento fundamental de la sociedad, y a recibir protección para ella”</p>

                <!-- add space -->
                <div class="h-8"></div>


                <figure>
                    <img src="../../assets/familia.jpg" alt="">
                    <figcaption><p class="mb-3 text-gray-500 dark:text-gray-400"><span class="italic"></span></p></figcaption>
                </figure>

                <!-- add space -->
                <div class="h-8"></div>


                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                    El Derecho de Familia propende a la protección de la maternidad y la la infancia. Y esta protección alcanza un modelo de familia que ha ido variando en el tiempo y que incluye nuevos paradigmas y una perspectiva de género de reciente data, pero que ha influido positivamente en la evolución legislativa y en las decisiones judiciales.
                </p>
                    <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                    Nuestra asesoría está orientada a aspectos tan relevantes como el derecho de alimentos, el cuidado personal, la tuición, las medidas de protección, el divorcio, la compensación económica y la violencia intrafamiliar.
                </p>
                    <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                    Es importante tener en cuenta el derecho e igualdad de los cónyuges, los criterios de protección; el establecimiento de medidas de progenitores e hijos.
                </p>



            </article>
        </div>
    </main>

    <ArticleAside_2 />

</template>

<script>
    import TopBanner2 from "../../components/TopBanner2.vue";
    import ArticleAside_2 from "../../components/ArticleAside_2.vue";

    export default {
        name: "DerechoFamilia",
        components: {
            TopBanner2,
            ArticleAside_2,
        }
    };

</script>