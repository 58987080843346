<template>
    <TopBanner2 />
    <BajadaCarrusel />
    <!-- <CTA_1 /> -->
    <!--<CTA_2 /> -->
    <CTA_3 />

    <FooterComponent />

</template>

<script>
    import TopBanner2 from '../components/TopBanner2.vue'
    import BajadaCarrusel from '../components/BajadaCarrusel.vue'
    // import CTA_1 from '../components/CTA_1.vue'
    // import CTA_2 from '../components/CTA_2.vue'
    import CTA_3 from '../components/CTA_3.vue'

    import FooterComponent from '../components/FooterComponent.vue'

    export default {
        name: "HomeView",
        components: {
            TopBanner2,
            BajadaCarrusel,
            // CTA_1,
            // CTA_2,
            CTA_3,
            FooterComponent,

        }
    };

</script>

<style scoped>
    /* You can add more styles here if you like */
</style>
