<template>
    <section class="bg-white dark:bg-gray-900 justify-center py-12">
        <div class="container mx-auto px-6">
            <!-- Section Title -->
            <!--
            <div class="text-center mb-12">
              <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Designed for business teams like yours</h2>
              <p class="text-gray-500 sm:text-xl dark:text-gray-400">bla bla </p>
            </div>
                  -->
            <!-- Features -->
            <div class="flex flex-wrap justify-center gap-10">
                <!-- Feature 1 -->
                <div class="max-w-sm">
                    <div class="flex items-center justify-center h-20 w-20 mb-4 mx-auto">
                        <!-- Icon placeholder -->
                        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">

                            <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 16h-1V2a1 1 0 1 0 0-2H2a1 1 0 0 0 0 2v14H1a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM5 4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4Zm0 5V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1Zm6 7H7v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm2-7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Zm0-4a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Z"></path></svg>
                        </div>
                    </div>
                    <h3 class="mb-2 text-xl font-bold dark:text-white text-center">Asesoría Inmobiliaria</h3>
                    <p class="text-gray-500 dark:text-gray-400" style="text-align: justify;">
                        Revisión y elaboración de contratos de promesa y compraventa
                        de bienes raíces, estudios de títulos; arrendamientos, usufructos,
                        derechos de herencia y posesiones efectivas. Consultoría en
                        negociaciones y litigios inmobiliarios.

                    </p>
                    <a href="/art_1" class="text-blue-600 hover:underline">Conoce más →</a>
                </div>
                <!-- Feature 2 -->
                <div class="max-w-sm">
                    <div class="flex items-center justify-center h-20 w-20 mb-4 mx-auto">
                        <!-- Icon placeholder -->
                        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd"></path><path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                            </svg>
                        </div>
                    </div>
                    <h3 class="mb-2 text-xl font-bold dark:text-white text-center">Derecho contractual e indemnizaciones de perjuicios</h3>
                    <p class="text-gray-500 dark:text-gray-400" style="text-align: justify;">
                        Revisión y redacción de contratos y asesoría en disputas contractuales;
                        demandas de indemnizaciones por daños y perjuicios.

                    </p>
                    <a href="#" class="text-blue-600 hover:underline">Conoce más →</a>
                </div>
                <!-- Feature 3 -->
                <div class="max-w-sm">
                    <div class="flex items-center justify-center h-20 w-20 mb-4 mx-auto">
                        <!-- Icon placeholder -->
                        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
                            
                            </svg>
                        </div>
                    </div>
                    <h3 class="mb-2 text-xl font-bold dark:text-white text-center">Defensoría Penal</h3>
                    <p class="text-gray-500 dark:text-gray-400" style="text-align: justify;">
                        Asesoría en la defensa e investigación de causas penales; representación
                        en diversos juicios orales para la correcta y justa aplicación del derecho.

                    </p>
                    <a href="#" class="text-blue-600 hover:underline">Conoce más →</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'CTA_1'
  // You can add your script details here
};</script>