
<template>
    <nav class="bg-green-700 border-gray-200 dark:border-gray-600">

        <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
            <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse">
                <!--<img src="../assets/logo_1.png" class="h-8" alt="" /> -->
                <span class="text-white text-xl font-semibold whitespace-nowrap dark:text-white">Tulio Gonz&aacute;lez Abuter</span>
            </a>

            <div id="mega-menu-full" class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1">
                <ul class="flex flex-col mt-4 font-medium md:flex-row md:mt-0 md:space-x-8 rtl:space-x-reverse">

                    <li>
                        <a href="/" class="block py-2 px-3 text-white border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-blue-500 md:dark:hover:bg-transparent dark:border-gray-700">Inicio</a>
                    </li>
                    <li>
                        <!--- Button -->
                        <button @click="toggleDropdown"
                                class="flex items-center justify-between w-full py-2 px-3 font-medium text-white border-b border-gray-100 md:w-auto hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-600 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-blue-500 md:dark:hover:bg-transparent dark:border-gray-700">
                            Servicios
                            <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                            </svg>
                        </button>
                    </li>

                    <li>
                        <a href="/ContactUs" class="block py-2 px-3 text-white border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-blue-500 md:dark:hover:bg-transparent dark:border-gray-700">Contacto</a>
                        <!--- /ContactUs -->
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="isDropdownOpen" id="mega-menu-full-dropdown" class="mt-1 bg-white border-gray-200 shadow-sm border-y dark:bg-gray-800 dark:border-gray-600">
            <div class="grid max-w-screen-xl px-4 py-5 mx-auto text-gray-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6">

                <ul aria-labelledby="mega-menu-full-dropdown-button">
                    <li>
                        <a href="/servicios/AsesoriaInmobiliaria" class="block p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                            <div class="font-semibold">Asesoría Inmobiliaria</div>
                            <span class="text-sm text-gray-500 dark:text-gray-400"><p style="text-align: justify;">Es esencial conocer el mercado inmobiliario, las leyes y los planes de planificación territorial para una efectiva compra y venta de bienes raíces. </p> </span>
                        </a>
                    </li>
                    <li>
                        <a href="/servicios/Corretajes" class="block p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                            <div class="font-semibold">Corretaje</div>
                            <span class="text-sm text-gray-500 dark:text-gray-400"><p style="text-align: justify;"> El corretaje de bienes raíces, vital en el sector inmobiliario, implica mediar entre partes y asesorar, incluyendo conocimientos sobre créditos hipotecarios y legalidad de propiedades.</p> </span>
                        </a>
                    </li>
                    <li>
                        <a href="/servicios/EstudiosTitulos" class="block p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                            <div class="font-semibold">Estudios de títulos</div>
                            <span class="text-sm text-gray-500 dark:text-gray-400"> <p style="text-align: justify;"> Para asegurar una compra inmobiliaria segura, es crucial realizar un estudio de títulos y verificar la legalidad de la propiedad con el Conservador de Bienes Raíces. </p>  </span>
                        </a>
                    </li>
                </ul>
                <ul>
                    <li>
                        <a href="/servicios/DerechoFamilia" class="block p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                            <div class="font-semibold">Derecho de Familia</div>
                            <span class="text-sm text-gray-500 dark:text-gray-400"><p style="text-align: justify;">Este derecho integra reglas familiares y éticas, priorizando el bienestar de los menores y la equidad entre cónyuges. La orientación legal es esencial en asuntos de manutención, custodia y divorcio, abordados en los Tribunales de Familia.</p> </span>
                        </a>
                    </li>
                    <li>
                        <a href="/servicios/DerechoContractual" class="block p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                            <div class="font-semibold">Derecho contractual e indemnizaciones de perjuicios </div>
                            <span class="text-sm text-gray-500 dark:text-gray-400"><p style="text-align: justify;"> El Derecho contractual se enfoca en asegurar que los acuerdos entre personas o empresas sean justos y cumplan con la ley. Una buena asesoría legal es crucial para entender y redactar estos acuerdos.</p> </span>
                        </a>
                    </li>
                    <li>
                        <a href="/servicios/herencias" class="block p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                            <div class="font-semibold">Herencias</div>
                            <span class="text-sm text-gray-500 dark:text-gray-400"><p style="text-align: justify;">Se realizan trámites de posesión Efectiva, identificando pasivos, activos y herederos, y calculando impuestos.</p> </span>
                        </a>
                    </li>
                </ul>
                <ul class="hidden md:block">

                    <li>
                        <a href="/servicios/DefensoriaPenal" class="block p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                            <div class="font-semibold">Defensoría penal</div>
                            <span class="text-sm text-gray-500 dark:text-gray-400"><p style="text-align: justify;">El sistema penal chileno asegura un debido proceso y adapta procedimientos al delito. Todo acusado tiene derecho a defensa especializada.</p> </span>
                        </a>
                    </li>

                    <li>
                        <a href="/servicios/RecursoAmparo" class="block p-3 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
                            <div class="font-semibold">Recursos de amparo y protección</div>
                            <span class="text-sm text-gray-500 dark:text-gray-400"><p style="text-align: justify;">Los recursos de amparo y protección buscan salvaguardar derechos fundamentales cuando son vulnerados. Estos recursos se presentan en tribunales para corregir situaciones injustas, como violaciones a la privacidad o libertad. La orientación legal es crucial para guiar estos procesos.</p> </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

    </nav>
</template>

<script>
    import {  onMounted} from 'vue';
    import { initFlowbite } from 'flowbite';
    export default {
        name: 'TopBanner2',
        data() {
            return {
                // This property will track whether the dropdown is open
                isDropdownOpen: false,
            };
        },
        methods: {
            toggleDropdown() {
                // This method toggles the state of the dropdown
                this.isDropdownOpen = !this.isDropdownOpen;
            },
        },

        setup() {
            onMounted(() => {
                initFlowbite();
            });
        },
    };
</script>