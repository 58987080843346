<template>
    <TopBanner2 />

    <main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
        <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
            <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                <header class="mb-4 lg:mb-6 not-format">
                    <address class="flex items-center mb-6 not-italic">
                        <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                            <img class="mr-4 w-16 h-16 rounded-full" src="../../assets/foto_perfil_2.jpg" alt="foto_perfil">
                            <div>
                                <a href="#" rel="author" class="text-xl font-bold text-gray-900 dark:text-white">Tulio González Abuter</a>
                                <p class="text-base text-gray-500 dark:text-gray-400">Abogado</p>
                                <p class="text-base text-gray-500 dark:text-gray-400"><time pubdate datetime="2023-11-21" title="21 de Noviembre, 2023"><!-- 21 de Noviembre, 2023 --></time></p>
                            </div>
                        </div>
                    </address>
                    <h1 style="text-align: center;" class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">Derecho contractual e indemnización de perjuicios</h1>
                </header>
                <!-- texto alineado -->

                <p class="mb-3 text-lg text-gray-500 md:text-xl dark:text-gray-400" style="text-align: justify;">Uno de los pilares del derecho privado moderno lo constituye la libertad contractual y la autonomía de la voluntad, que se aprecia mejor en sociedades donde existe libertad de emprendimiento y una profusa circulación de bienes y servicios. </p>

                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                    Aún más, la internacionalización cada vez más creciente del comercio internacional, produce infinidad de contratos que muchas veces resultan mal redactados o con evidentes omisiones que provocan conflictos que los jueces árbitros o bien los jueces ordinarios deben resolver. Por ello es importante estar siempre al día en las tendencias y cambios legislativos y en el estudio de la jurisprudencia.

                </p>

                <!-- add space -->
                <div class="h-8"></div>


                <figure>
                    <img src="../../assets/contract.jpg" alt="">
                    <figcaption><p class="mb-3 text-gray-500 dark:text-gray-400"><span class="italic"></span></p></figcaption>
                </figure>

                <!-- add space -->
                <div class="h-8"></div>


                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">Muchos de estos conflictos derivan en el cumplimiento forzado de las obligaciones contraídas o en indemnizaciones, por lo que se requiere también mucho estudio y habilidades para demostrar y probar perjuicios imputables a la parte que no ha cumplido el contrato. </p>

                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;"> Del mismo modo, a la luz del estudio de muchos contratos descubrimos cláusulas abusivas, abuso del derecho e incluso estafas. Otros de estos actos jurídicos terminan en nulidad o en resolución. </p>

                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                    Las indemnizaciones de perjuicios no solo se producen por incumplimientos, sino también por faltas, delitos, cuasidelitos; falta de servicios o negligencia profesional.
                </p>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                    Una de las áreas de mayor crecimiento en el derecho indemnizatorio es derivado de la falta de servicios y/o negligencia médica, donde se requiere una especial dedicación al estudio de cada caso.
                </p>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">Un estudio jurídico implica conocer a cabalidad el sentido y alcance de los términos en que están redactados de los contratos y las leyes que los rigen.</p>



            </article>
        </div>
    </main>

    <ArticleAside_2 />

</template>

<script>
    import TopBanner2 from "../../components/TopBanner2.vue";
    import ArticleAside_2 from "../../components/ArticleAside_2.vue";

    export default {
        name: "DerechoContractual",
        components: {
            TopBanner2,
            ArticleAside_2,
        }
    };

</script>