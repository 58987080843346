<template>
    <TopBanner2 />
    <!--- <div class="container mx-auto p-4"> -->
    <section class="bg-white dark:bg-gray-900">
        <div class="gap-24 items-start py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
            <section class="bg-white dark:bg-gray-900 h-screen">
                <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400 py-8 lg:py-16 px-4 lg:px-8 xl:px-16">
                    <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white text-center">Nuestra Dirección</h2>
                    <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
                        Nuestra oficina se encuentra ubicada en la ciudad de Los Ángeles, en la Región del Bío Bío, Chile.
                    </p>
                    <div class="flex justify-center">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.2792925510503!2d-72.3559707!3d-37.4777351!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966bdd356dc50613%3A0x2fecfcb6115704fe!2sCondell%20585%2C%204430430%20Los%20Angeles%2C%20B%C3%ADo%20B%C3%ADo%2C%20Chile!5e0!3m2!1sfr!2sfr!4v1702467728167!5m2!1sfr!2sfr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>


            <!--- Formulario -->
            <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400 py">

                <section class="bg-white dark:bg-gray-900 h-screen">
                    <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">Contácteme</h2>
                        <p class="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
                            Me encantaría escuchar de ti. Si tienes alguna pregunta, comentario o sugerencia, no dudes en contactarme. Trataré de contactarlo lo antes posible.
                        </p>
                        <form @submit.prevent="checkMessages" class="space-y-8">
                            <div>
                                <label for="input_email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Tu e-mail</label>
                                <input v-model="input_email" type="email" id="input_email" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required>
                                <p v-if="empty_email" class="text-red-600 text-sm">Please provide an email address.</p>
                            </div>
                            <div>
                                <label for="input_telefono" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Teléfono</label>
                                <input v-model="input_telefono" type="text" id="input_telefono" class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required>

                            </div>
                            <div>
                                <label for="input_subject" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Asunto</label>
                                <input v-model="input_subject" type="text" id="input_subject" class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="" required>

                            </div>
                            <div class="sm:col-span-2">
                                <label for="text_message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Tu mensaje</label>
                                <textarea v-model="text_message" id="text_message" rows="6" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Dime cómo te puedo ayudar..."></textarea>
                                <p v-if="empty_message" class="text-red-600 text-sm">Please leave a message so we can reply to you.</p>
                            </div>


                            <!--- <button type="submit" class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Send message</button> -->
                            <div class="flex justify-center m-5">
                                <button id="successButton" class="block text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800" type="submit">
                                    Enviar
                                </button>
                            </div>

                        </form>
                    </div>
                </section>
            </div>
        </div>
    </section>
</template>


<script>
    import { ref } from 'vue';
    import TopBanner2 from '../components/TopBanner2.vue';

    import axios from 'axios';
    import { useRouter } from 'vue-router';


    export default {
        name: 'ContactUs',
        components: {
            TopBanner2,
        },

        setup() {
            const input_email = ref('');
            const input_telefono = ref('');
            const input_subject = ref('');
            const text_message = ref('');

            const empty_email = ref(false);
            const empty_message = ref(false);
            const router = useRouter();


            const checkMessages = () => {
                if (!input_email.value) {
                    empty_email.value = true;
                } else if (!text_message.value) {
                    empty_message.value = true;
                } else {
                    submitForm();
                }
            };

            const submitForm = async () => {
                try {
                    const response = await axios.post('https://b2fp4p14ji.execute-api.us-east-2.amazonaws.com/dev_ops', {
                        email: input_email.value,
                        telefono: input_telefono.value,
                        subject: input_subject.value,
                        message: text_message.value,
                    });

                    if (response.data.statusCode === 200) {

                        router.push('/GraciasContacto').then(() => {
                            // Do something after navigation, if needed
                        });
                    }

                } catch (error) {
                    console.error(error);
                }
            }

            return { input_email, input_subject, input_telefono, text_message, checkMessages, submitForm, empty_email, empty_message }
        }
    };
</script>