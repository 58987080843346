<template>
    <div class="bg-green-500">
        <nav class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex items-center justify-between h-16">
                <!-- Logo or brand name -->
                <div class="flex-shrink-0">
                    <a href="/" class="text-white font-bold text-xl">
                        Tulio Gonz&aacute;lez Abuter
                    </a>
                </div>
                <!-- Navigation Links -->
                <div class="hidden md:block">
                    <div class="ml-10 flex items-baseline space-x-4">
                        <a href="#" class="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-green-600">
                            Servicios
                        </a>
                        
                        

                        <a href="#" class="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-green-600">
                            Contacto
                        </a>
                        <!-- Add additional navigation links here -->
                    </div>
                </div>
                <!-- Mobile menu button -->
                <div class="-mr-2 flex md:hidden">
                    <!-- Mobile menu button -->
                    <button type="button" class="bg-green-600 inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <!-- Icon for menu button -->
                    </button>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>export default {
  name: 'TopBanner'
  // You can add your script details here
};</script>

<style scoped>
    /* If you need additional custom styling, you can add it here */
</style>
