<template>
    <TopBanner2 />

    <main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
        <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
            <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                <header class="mb-4 lg:mb-6 not-format">
                    <address class="flex items-center mb-6 not-italic">
                        <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                            <img class="mr-4 w-16 h-16 rounded-full" src="../../assets/foto_perfil_2.jpg" alt="foto_perfil">
                            <div>
                                <a href="#" rel="author" class="text-xl font-bold text-gray-900 dark:text-white">Tulio González Abuter</a>
                                <p class="text-base text-gray-500 dark:text-gray-400">Abogado</p>
                                <p class="text-base text-gray-500 dark:text-gray-400"><time pubdate datetime="2023-11-21" title="21 de Noviembre, 2023"><!-- 21 de Noviembre, 2023 --></time></p>
                            </div>
                        </div>
                    </address>
                    <h1 style="text-align: center;" class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">Servicios Profesionales de Corretaje Inmobiliario</h1>
                </header>
                <!-- texto alineado -->

                <p class="mb-3 text-lg text-gray-500 md:text-xl dark:text-gray-400" style="text-align: justify;">Bienvenidos a nuestra sección dedicada al Corretaje Inmobiliario, donde combinamos nuestra experiencia legal con un conocimiento del mercado inmobiliario para ofrecerle un servicio de corretaje de la más alta calidad. </p>

                <h4 class="text-2xl font-bold dark:text-white">Nuestros Servicios de Corretaje</h4>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">Brindamos servicios integrales de corretaje, adaptándonos a las necesidades específicas de cada cliente, ya sea en la compra, venta o arrendamiento de propiedades. Nuestros servicios incluyen:</p>



                <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-gray-400">
                    <li style="text-align: justify;">
                        <strong>Evaluación de Propiedades:</strong>. Realizamos, a través de profesionales especializados, un análisis detallado del valor de mercado de su propiedad.

                    </li>
                    <li style="text-align: justify;">
                        <strong>Promoción y Marketing Inmobiliario:</strong>. Utilizamos estrategias de marketing innovadoras y efectivas para promocionar su propiedad, alcanzando a un amplio espectro de potenciales compradores o arrendatarios.
                    </li>
                    <li style="text-align: justify;">
                        <strong>Búsqueda Personalizada de Propiedades:</strong>. Ofrecemos un servicio de búsqueda personalizada para encontrar la propiedad que mejor se ajuste a sus necesidades y expectativas, ya sea para compra o arrendamiento.
                    </li>
                    <li style="text-align: justify;">
                        <strong>Negociación y Cierre de Transacciones: </strong>. Nuestra experiencia legal nos permite manejar las negociaciones con habilidad y eficiencia, asegurando el mejor acuerdo posible y una transacción fluida y segura.
                    </li>
                    <li style="text-align: justify;">
                        <strong>Asesoría Legal Integral:  </strong>. Brindamos asesoría legal en cada etapa del proceso de corretaje, incluyendo la revisión y elaboración de contratos, asesoramiento en la firma de escrituras y todo lo necesario para garantizar una transacción conforme a la ley.
                    </li>
                </ol>






                <!-- add space -->
                <div class="h-8"></div>


                <figure>
                    <img src="../../assets/corretajes.jpg" alt="">
                    <figcaption><p class="mb-3 text-gray-500 dark:text-gray-400"><span class="italic"></span></p></figcaption>
                </figure>

                <!-- add space -->
                <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Nuestro Compromiso</h2>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                    Cada cliente tiene necesidades únicas y que cada propiedad es especial. Nuestro compromiso es brindar un servicio personalizado y de alta calidad, asegurando que cada transacción se realice de manera eficiente, transparente y con el máximo beneficio para nuestros clientes.
                </p>
                <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">Contáctenos</h2>

                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">
                    Para más información sobre nuestros servicios de corretaje inmobiliario o para programar una cita, por favor contáctenos. Estamos listos para ayudarle a lograr sus objetivos inmobiliarios con profesionalismo y confianza.

                </p>

                <!-- add space -->
                <div class="h-8"></div>




            </article>
        </div>
    </main>

    <ArticleAside_2 />

</template>

<script>
    import TopBanner2 from "../../components/TopBanner2.vue";
    import ArticleAside_2 from "../../components/ArticleAside_2.vue";

    export default {
        name: "corretajes_page",
        components: {
            TopBanner2,
            ArticleAside_2,
        }
    };

</script>