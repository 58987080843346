<template>
    <TopBanner2 />

    <main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
        <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
            <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                <header class="mb-4 lg:mb-6 not-format">
                    <address class="flex items-center mb-6 not-italic">
                        <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                            <img class="mr-4 w-16 h-16 rounded-full" src="../../assets/foto_perfil_2.jpg" alt="foto_perfil">
                                <div>
                                    <a href="#" rel="author" class="text-xl font-bold text-gray-900 dark:text-white">Tulio González Abuter</a>
                                    <p class="text-base text-gray-500 dark:text-gray-400">Abogado</p>
                                    <p class="text-base text-gray-500 dark:text-gray-400"><time pubdate datetime="2023-11-21" title="21 de Noviembre, 2023"><!-- 21 de Noviembre, 2023 --></time></p>
                                </div>
                        </div>
                    </address>
                 <h1 style="text-align: center;" class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">Asesoría Inmobiliaria</h1>
                </header>
                <!-- texto alineado -->

                <p class="mb-3 text-lg text-gray-500 md:text-xl dark:text-gray-400" style="text-align: justify;" >La asesoría inmobiliaria es un servicio especializado que ofrece orientación y apoyo a individuos o empresas en el proceso de comprar, vender, 
              alquilar o administrar propiedades inmobiliarias. </p>

                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">Los asesores inmobiliarios son expertos en el mercado de bienes raíces y proporcionan información vital sobre 
              tendencias del mercado, valoraciones de propiedades, y consejos legales y fiscales relacionados con transacciones inmobiliarias. 
                Estos profesionales ayudan a sus clientes a tomar decisiones informadas, maximizando el valor de sus inversiones. La asesoría puede incluir análisis de mercado, 
              identificación de propiedades que se ajustan a las necesidades y presupuesto del cliente, negociación de precios y términos de contrato, y asistencia en el proceso 
              de cierre de la transacción. Además, pueden ofrecer servicios post-venta, como la gestión de propiedades y asesoramiento para futuras inversiones.</p>

                <!-- add space -->
                <div class="h-8"></div>


                <figure><img src="../../assets/edificio_1.jpg" alt="">
                    <figcaption><p class="mb-3 text-gray-500 dark:text-gray-400"><span class="italic">Edificio en construcción, en Los ángeles, región del Biobío.</span></p></figcaption>
                </figure>

                <!-- add space -->
                <div class="h-8"></div>


                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">Los asesores inmobiliarios suelen tener un conocimiento profundo de la ley local, reglamentos de construcción, y procesos de financiamiento, lo que les permite 
                  guiar a sus clientes a través de complejos procedimientos legales y administrativos. Su experiencia y habilidades de negociación son fundamentales para lograr 
                  transacciones exitosas y satisfactorias para todas las partes involucradas. </p>

                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;"> En resumen, la asesoría inmobiliaria es esencial para navegar con éxito el mercado inmobiliario, garantizando que las decisiones de compra, venta o alquiler de 
                  propiedades se realicen de manera informada, eficiente y beneficiosa. </p>

                <!-- add space -->
                <div class="h-8"></div>

                <h4 class="text-2xl font-bold dark:text-white">¿Cuándo es útil la asesoría inmobiliaria?</h4>
                <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">Aunque a primera vista puede parecer un trabajo adicional, aquí hay algunos momentos clave en los que la asesoría inmobiliaria resulta especialmente útil:</p>
                <ol>
                    <li><strong class="font-semibold text-gray-900 dark:text-white">Identificación de oportunidades de inversión</strong>. <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">  ¿Estás buscando la propiedad adecuada para invertir? Un asesor inmobiliario puede ayudarte a identificar oportunidades de mercado que se ajusten a tus objetivos financieros y a tu perfil de riesgo, asegurando una inversión inteligente y rentable.</p></li>
                    <li><strong class="font-semibold text-gray-900 dark:text-white">Soporte legal y normativo</strong>. <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;"> ¿Necesitas ayuda con los complejos procedimientos legales y normativos del sector inmobiliario? Los asesores inmobiliarios están equipados para guiarte a través de estos procesos, asegurando que tu transacción cumpla con todas las leyes y reglamentaciones aplicables.</p></li>
                    <li><strong class="font-semibold text-gray-900 dark:text-white">Maximización del valor de las propiedades</strong>. <p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;"> ¿Quieres obtener el máximo rendimiento de tu inversión? Los asesores inmobiliarios pueden proporcionar estrategias efectivas para aumentar el valor de tus propiedades, ya sea a través de mejoras, reestructuración de arrendamientos o técnicas de marketing adecuadas.</p></li>
                    <li><strong class="font-semibold text-gray-900 dark:text-white">Facilitación de procesos de negociación</strong>.<p class="mb-3 text-gray-500 dark:text-gray-400" style="text-align: justify;">  Al comprar o vender una propiedad, los asesores inmobiliarios actúan como intermediarios, asegurando que las negociaciones se lleven a cabo de manera justa y eficiente, y que se alcancen acuerdos beneficiosos para todas las partes involucradas.</p></li>
                </ol>

              
            </article>
        </div>
    </main>

    <ArticleAside_2 />

</template>

<script>
    import TopBanner2 from "../../components/TopBanner2.vue";
    import ArticleAside_2 from "../../components/ArticleAside_2.vue";

    export default {
        name: "AsesoriaInmobiliaria",
        components: {
            TopBanner2,
            ArticleAside_2,
        }
    };

</script>