<template>
    <section class="bg-white dark:bg-gray-900 justify-center py-12">
        <div class="container mx-auto px-6">
            <!-- Section Title -->
            <!--
            <div class="text-center mb-12">
              <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Designed for business teams like yours</h2>
              <p class="text-gray-500 sm:text-xl dark:text-gray-400">bla bla </p>
            </div>
                  -->
            <!-- Features -->
            <div class="flex flex-wrap justify-center gap-10">
                <!-- Feature 1 -->
                <div class="max-w-sm">
                    <div class="flex items-center justify-center h-20 w-20 mb-4 mx-auto">
                        <!-- Icon placeholder -->
                        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z"></path></svg>
                        </div>
                    </div>
                    <h3 class="mb-2 text-xl font-bold dark:text-white text-center">Estudios de títulos</h3>
                    <p class="text-gray-500 dark:text-gray-400" style="text-align: justify;">
                        Se evalúan Estudios de Títulos para propiedades, asegurando la correcta verificación de titularidades y la identificación de gravámenes, así como la validez de documentos como escrituras y registros públicos.

                    </p>
                    <a href="#" class="text-blue-600 hover:underline">Conoce más →</a>
                </div>
                <!-- Feature 2 -->
                <div class="max-w-sm">
                    <div class="flex items-center justify-center h-20 w-20 mb-4 mx-auto">
                        <!-- Icon placeholder -->
                        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 19">
                                <path d="M14.5 0A3.987 3.987 0 0 0 11 2.1a4.977 4.977 0 0 1 3.9 5.858A3.989 3.989 0 0 0 14.5 0ZM9 13h2a4 4 0 0 1 4 4v2H5v-2a4 4 0 0 1 4-4Z" />
                                <path d="M5 19h10v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2ZM5 7a5.008 5.008 0 0 1 4-4.9 3.988 3.988 0 1 0-3.9 5.859A4.974 4.974 0 0 1 5 7Zm5 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm5-1h-.424a5.016 5.016 0 0 1-1.942 2.232A6.007 6.007 0 0 1 17 17h2a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5ZM5.424 9H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h2a6.007 6.007 0 0 1 4.366-5.768A5.016 5.016 0 0 1 5.424 9Z" />


                            </svg>
                        </div>
                    </div>
                    <h3 class="mb-2 text-xl font-bold dark:text-white text-center">Derecho de Familia</h3>
                    <p class="text-gray-500 dark:text-gray-400" style="text-align: justify;">
                        Revisión y redacción de contratos y asesoría en disputas contractuales;
                        demandas de indemnizaciones por daños y perjuicios.

                    </p>
                    <a href="#" class="text-blue-600 hover:underline">Conoce más →</a>
                </div>
                <!-- Feature 3 -->
                <div class="max-w-sm">
                    <div class="flex items-center justify-center h-20 w-20 mb-4 mx-auto">
                        <!-- Icon placeholder -->
                        <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                            <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.168 6.136 14.325.293a1 1 0 0 0-1.414 0l-1.445 1.444a1 1 0 0 0 0 1.414l5.844 5.843a1 1 0 0 0 1.414 0l1.444-1.444a1 1 0 0 0 0-1.414Zm-4.205 2.927L11.4 4.5a1 1 0 0 0-1-.25L4.944 5.9a1 1 0 0 0-.652.624L.518 17.206a1 1 0 0 0 .236 1.04l.023.023 6.606-6.606a2.616 2.616 0 1 1 3.65 1.304 2.615 2.615 0 0 1-2.233.108l-6.61 6.609.024.023a1 1 0 0 0 1.04.236l10.682-3.773a1 1 0 0 0 .624-.653l1.653-5.457a.999.999 0 0 0-.25-.997Z" />
                                <path d="M10.233 11.1a.613.613 0 1 0-.867-.868.613.613 0 0 0 .867.868Z" />
                            </svg>
                        </div>
                    </div>
                    <h3 class="mb-2 text-xl font-bold dark:text-white text-center">Recursos de amparo y protección</h3>
                    <p class="text-gray-500 dark:text-gray-400" style="text-align: justify;">
                        Se preparan recursos con argumentación legal sólida, asegurando una protección efectiva de los derechos de los clientes en todas las etapas judiciales.
                    </p>
                    <a href="#" class="text-blue-600 hover:underline">Conoce más →</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'CTA_1'
  // You can add your script details here
};</script>