import { createRouter, createWebHistory } from 'vue-router'

// Components
import ArticleAside_1 from '../components/ArticleAside_1.vue'
import ArticleAside_2 from '../components/ArticleAside_2.vue'
import BajadaCarrusel from '../components/BajadaCarrusel.vue'
import CTA_1 from '../components/CTA_1.vue'
import CTA_2 from '../components/CTA_2.vue'
import CTA_3 from '../components/CTA_3.vue'
import FooterComponent from '../components/FooterComponent.vue'
import TopBanner from '../components/TopBanner.vue'
import TopBanner2 from '../components/TopBanner2.vue'

// Views
import HomeView from '../views/HomeView.vue'
import ContactUs from '../views/ContactUs.vue'
import GraciasContacto from '../views/GraciasContacto.vue'

// Servicios
import AsesoriaInmobiliaria from '../views/servicios/AsesoriaInmobiliaria.vue'
import DerechoContractual from '../views/servicios/DerechoContractual.vue'
import DerechoFamilia from '../views/servicios/DerechoFamilia.vue'
import Herencias from '../views/servicios/Herencias.vue'
import Corretajes from '../views/servicios/Corretajes.vue'
import EstudiosTitulos from '../views/servicios/EstudiosTitulos.vue'
import DefensoriaPenal from '../views/servicios/DefensoriaPenal.vue'
import RecursoAmparo from '../views/servicios/RecursoAmparo.vue'



const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/GraciasContacto',
        name: 'GraciasContacto',
        component: GraciasContacto
    },
    {
        path: '/servicios/DefensoriaPenal',
        name: 'DefensoriaPenal',
        component: DefensoriaPenal
    },
    {
        path: '/servicios/RecursoAmparo',
        name: 'RecursoAmparo',
        component: RecursoAmparo
    },
    {
        path: '/servicios/EstudiosTitulos',
        name: 'EstudiosTitulos',
        component: EstudiosTitulos
    },
    {
        path: '/servicios/Corretajes',
        name: 'Corretajes',
        component: Corretajes
    },
    {
        path: '/servicios/AsesoriaInmobiliaria',
        name: 'AsesoriaInmobiliaria',
        component: AsesoriaInmobiliaria
    },
    {
        path: '/servicios/DerechoContractual',
        name: 'DerechoContractual',
        component: DerechoContractual
    },
    {
        path: '/servicios/DerechoFamilia',
        name: 'DerechoFamilia',
        component: DerechoFamilia
    },
    {
        path: '/servicios/Herencias',
        name: 'Herencias',
        component: Herencias
    },
    {
        path: '/ArticleAside_1',
        name: 'ArticleAside_1',
        component: ArticleAside_1
    },
    {
        path: '/ArticleAside_2',
        name: 'ArticleAside_2',
        component: ArticleAside_2
    },
    {
        path: '/BajadaCarrusel',
        name: 'BajadaCarrusel',
        component: BajadaCarrusel
    },
    {
        path: '/CTA_1',
        name: 'CTA_1',
        component: CTA_1
    },
    {
        path: '/CTA_2',
        name: 'CTA_2',
        component: CTA_2
    },
        {
        path: '/CTA_3',
        name: 'CTA_3',
        component: CTA_3
    },
    {
        path: '/ContactUs',
        name: 'ContactUs',
        component: ContactUs

    },
    {
        path: '/FooterComponent',
        name: 'FooterComponent',
        component: FooterComponent
    },
    {
        path: '/TopBanner',
        name: 'TopBanner',
        component: TopBanner
    },
    {
        path: '/TopBanner2',
        name: 'TopBanner2',
        component: TopBanner2
    },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
