<template>
    <div class="bg-white dark:bg-gray-900 container mx-auto my-0 p-8">
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
            <!-- Image Section -->
            <div class="flex-1">
                <img src="../assets/libros.jpg" alt="Team Working" class="rounded-lg shadow-lg">
            </div>

            <!-- Content Section -->
            <div class="flex-1">
                <h2 class="text-3xl font-bold mb-6 dark:text-white" style="text-align: center;">Soluciones Jurídicas Integrales en Derecho Contractual y de Familia</h2>
                <p class="mb-8 text-gray-600" style="text-align: justify;">
                    Profundiza en nuestra especialización en Derecho Contractual con servicios de revisión, redacción de contratos y asesoramiento en litigios contractuales.
                </p>

                <!-- Feature List -->
                <div class="space-y-4">
                    <div class="flex items-start">
                        <div class="shrink-0">
                            <div class="p-2 bg-blue-100 rounded-lg">
                                <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd"></path>
                                    <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                                </svg>
                                <i class="fa-solid fa-lock"></i>
                            </div>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-lg font-semibold dark:text-white">Derecho contractual e indemnizaciones de perjuicios</h3>
                            <p class="text-gray-500 dark:text-gray-400" style="text-align: justify;">
                                Revisión y redacción de contratos y asesoría en disputas contractuales; demandas de indemnizaciones por daños y perjuicios.
                            </p>
                            <a href="/servicios/DerechoContractual" class="text-blue-600 hover:underline">Conoce más →</a>
                        </div>
                    </div>

                    <!-- Repeat for other features -->

                    <div class="flex items-start">
                        <div class="shrink-0">
                            <div class="p-2 bg-blue-100 rounded-lg">
                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 15V9m4 6V9m4 6V9m4 6V9M2 16h16M1 19h18M2 7v1h16V7l-8-6-8 6Z" />
                                </svg>
                                <i class="fa-solid fa-lock"></i>
                            </div>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-lg font-semibold dark:text-white">Herencias</h3>
                            <p class="text-gray-500 dark:text-gray-400" style="text-align: justify;">
                                Las herencias, que incluyen bienes y deudas del fallecido, son una consulta jurídica común. Iniciamos con la Posesión Efectiva, identificando pasivos, activos y herederos, y calculando impuestos. Una asesoría adecuada busca una distribución justa y equitativa.

                            </p>
                            <a href="/servicios/Herencias" class="text-blue-600 hover:underline">Conoce más →</a>
                        </div>
                    </div>

                    <!-- Repeat for other features -->
                    <div class="flex items-start">
                        <div class="shrink-0">
                            <div class="p-2 bg-blue-100 rounded-lg">
                                <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" xmlns="http://www.w3.org/2000/svg" fill="green" viewBox="0 0 20 19">
                                    <path d="M14.5 0A3.987 3.987 0 0 0 11 2.1a4.977 4.977 0 0 1 3.9 5.858A3.989 3.989 0 0 0 14.5 0ZM9 13h2a4 4 0 0 1 4 4v2H5v-2a4 4 0 0 1 4-4Z" />
                                    <path d="M5 19h10v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2ZM5 7a5.008 5.008 0 0 1 4-4.9 3.988 3.988 0 1 0-3.9 5.859A4.974 4.974 0 0 1 5 7Zm5 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm5-1h-.424a5.016 5.016 0 0 1-1.942 2.232A6.007 6.007 0 0 1 17 17h2a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5ZM5.424 9H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h2a6.007 6.007 0 0 1 4.366-5.768A5.016 5.016 0 0 1 5.424 9Z" />


                                </svg>
                                <i class="fa-solid fa-lock"></i>
                            </div>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-lg font-semibold dark:text-white">Derecho de Familia</h3>
                            <p class="text-gray-500 dark:text-gray-400" style="text-align: justify;">
                                Revisión y redacción de contratos y asesoría en disputas contractuales; demandas de indemnizaciones por daños y perjuicios.

                            </p>
                            <a href="/servicios/DerechoFamilia" class="text-blue-600 hover:underline">Conoce más →</a>
                        </div>
                    </div>



                </div>
            </div>
        </div>


        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">

            <!-- Content Section -->
            <div class="flex-1">
                <h2 class="text-3xl font-bold mb-6 dark:text-white" style="text-align: center;">Experto en Derecho Inmobiliario</h2>
                <p class="mb-8 text-gray-600" style="text-align: justify;">
                    Ofrecemos un servicio jurídico especializado que abarca desde el minucioso análisis de Estudios de Títulos para garantizar transacciones inmobiliarias seguras, hasta una asesoría integral en la elaboración de contratos y la resolución de disputas. Además, contamos con servicios profesionales de corretaje, conectando partes y facilitando acuerdos con la mayor seguridad y confianza. Descubre cómo podemos apoyarte en tus necesidades inmobiliarias.
                </p>

                <!-- Feature List -->
                <div class="space-y-4">

                    <!-- Repeat for other features -->
                    <div class="flex items-start">
                        <div class="shrink-0">
                            <div class="p-2 bg-blue-100 rounded-lg">
                                <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 16h-1V2a1 1 0 1 0 0-2H2a1 1 0 0 0 0 2v14H1a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM5 4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4Zm0 5V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1Zm6 7H7v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm2-7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Zm0-4a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Z"></path>
                                </svg>
                                <i class="fa-solid fa-lock"></i>
                            </div>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-lg font-semibold dark:text-white">Asesoría Inmobiliaria</h3>
                            <p class="text-gray-500 dark:text-gray-400" style="text-align: justify;">
                                Revisión y elaboración de contratos de promesa y compraventa de bienes raíces, estudios de títulos; arrendamientos, usufructos, derechos de herencia y posesiones efectivas. Consultoría en negociaciones y litigios inmobiliarios.

                            </p>
                            <a href="/servicios/AsesoriaInmobiliaria" class="text-blue-600 hover:underline">Conoce más →</a>
                        </div>
                    </div>
                    <!-- Repeat for other features -->
                    <div class="flex items-start">
                        <div class="shrink-0">
                            <div class="p-2 bg-blue-100 rounded-lg">
                                <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 16">
                                    <path stroke="purple" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1v14h16M4 10l3-4 4 4 5-5m0 0h-3.207M16 5v3.207" />
                                </svg>
                                <i class="fa-solid fa-lock"></i>
                            </div>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-lg font-semibold dark:text-white">Corretaje</h3>
                            <p class="text-gray-500 dark:text-gray-400" style="text-align: justify;">
                                El corretaje de bienes raíces, vital en el sector inmobiliario, implica mediar entre partes y asesorar, incluyendo conocimientos sobre créditos hipotecarios y legalidad de propiedades.

                            </p>
                            <a href="/servicios/Corretajes" class="text-blue-600 hover:underline">Conoce más →</a>
                        </div>
                    </div>
                    <!-- Repeat for other features -->
                    <div class="flex items-start">
                        <div class="shrink-0">
                            <div class="p-2 bg-blue-100 rounded-lg">
                                <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                    <path stroke="green" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.656 12.115a3 3 0 0 1 5.682-.015M13 5h3m-3 3h3m-3 3h3M2 1h16a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Zm6.5 4.5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
                                </svg>
                                <i class="fa-solid fa-lock"></i>
                            </div>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-lg font-semibold dark:text-white">Estudios de títulos</h3>
                            <p class="text-gray-500 dark:text-gray-400" style="text-align: justify;">
                                Se evalúan Estudios de Títulos para propiedades, asegurando la correcta verificación de titularidades y la identificación de gravámenes, así como la validez de documentos como escrituras y registros públicos.

                            </p>
                            <a href="/servicios/EstudiosTitulos" class="text-blue-600 hover:underline">Conoce más →</a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Image Section -->
            <div class="flex-1">
                <img src="../assets/edificio_1.jpg" alt="Team Working" class="rounded-lg shadow-lg">
            </div>


        </div>


        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
            <!-- Image Section -->
            <div class="flex-1">
                <img src="../assets/timbre.jpg" alt="Team Working" class="rounded-lg shadow-lg">
            </div>

            <!-- Content Section -->
            <div class="flex-1">
                <h2 class="text-3xl font-bold mb-6 dark:text-white" style="text-align: center;">Representación Legal en Defensa Penal</h2>
                <p class="mb-8 text-gray-600" style="text-align: justify;">
                    Brindamos una asesoría legal robusta en la defensa de causas penales, asegurando una representación firme en juicios orales para la aplicación justa de la ley. Nos especializamos igualmente en la preparación de recursos de amparo y protección, con argumentaciones sólidas para la salvaguarda efectiva de los derechos constitucionales de nuestros clientes en todos los niveles del proceso judicial. Conoce más sobre cómo podemos asistirte en la protección de tus derechos.
                </p>

                <!-- Feature List -->
                <div class="space-y-4">
                    <div class="flex items-start">
                        <div class="shrink-0">
                            <div class="p-2 bg-blue-100 rounded-lg">
                                <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>

                                </svg>
                                <i class="fa-solid fa-lock"></i>
                            </div>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-lg font-semibold dark:text-white" >Defensoría Penal</h3>
                            <p class="text-gray-500 dark:text-gray-400" style="text-align: justify;">
                                Asesoría en la defensa e investigación de causas penales; representación en diversos juicios orales para la correcta y justa aplicación del derecho.

                            </p>
                            <a href="/servicios/DefensoriaPenal" class="text-blue-600 hover:underline">Conoce más →</a>
                        </div>
                    </div>
                    <!-- Repeat for other features -->
                    <div class="flex items-start">
                        <div class="shrink-0">
                            <div class="p-2 bg-blue-100 rounded-lg">
                                <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" fill="green" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.168 6.136 14.325.293a1 1 0 0 0-1.414 0l-1.445 1.444a1 1 0 0 0 0 1.414l5.844 5.843a1 1 0 0 0 1.414 0l1.444-1.444a1 1 0 0 0 0-1.414Zm-4.205 2.927L11.4 4.5a1 1 0 0 0-1-.25L4.944 5.9a1 1 0 0 0-.652.624L.518 17.206a1 1 0 0 0 .236 1.04l.023.023 6.606-6.606a2.616 2.616 0 1 1 3.65 1.304 2.615 2.615 0 0 1-2.233.108l-6.61 6.609.024.023a1 1 0 0 0 1.04.236l10.682-3.773a1 1 0 0 0 .624-.653l1.653-5.457a.999.999 0 0 0-.25-.997Z" />
                                    <path d="M10.233 11.1a.613.613 0 1 0-.867-.868.613.613 0 0 0 .867.868Z" />
                                </svg>
                                <i class="fa-solid fa-lock"></i>
                            </div>
                        </div>
                        <div class="ml-3">
                            <h3 class="text-lg font-semibold dark:text-white">Recursos de amparo y protección</h3>
                            <p class="text-gray-500 dark:text-gray-400" style="text-align: justify;">
                                Se preparan recursos con argumentación legal sólida, asegurando una protección efectiva de los derechos de los clientes en todas las etapas judiciales.

                            </p>
                            <a href="/servicios/RecursoAmparo" class="text-blue-600 hover:underline">Conoce más →</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    export default {
        name: 'CTA_3'
    };

</script>